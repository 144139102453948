<template>
  <section>
    <div v-if="loading" class="text-center py-5">
      <div
        class="spinner-border"
        style="width: 3rem; height: 3rem;"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else>
      <div v-if="Object.keys(detail).length > 1">
        <div class="container p-4">
          <div class="row p-3 mb-5">
            <div
              class="col-lg-3 text-center d-flex align-items-start justify-content-center"
            >
              <img
                v-if="!checkLinkDrive"
                :src="imageURL"
                class="w-100 rounded"
                alt="image"
              />
              <iframe
                v-else
                style="width:100%;height:300px;border:0px;"
                :src="detail.cover"
                allow="autoplay"
              ></iframe>
            </div>
            <div class="col-lg-9 pt-5 pt-md-0">
              <h4 class="my-3">{{ detail.judul }}</h4>
              <button
                type="button"
                class="btn btn-sm btn-outline-primary"
                data-toggle="modal"
                data-target="#readModal"
              >
                Baca Online
              </button>
              <div class="row align-items-center my-3">
                <div class="col-6 col-lg-2">
                  <span>DETAIL BUKU</span>
                </div>
                <div class="col-5 col-lg-10 p-0">
                  <hr />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 mb-3">
                  <div>Jenis Pustaka</div>
                  <small class="text-muted">{{ detail.jenis_pustaka }}</small>
                </div>
                <div class="col-lg-4 mb-3">
                  <div>Tahun Terbit</div>
                  <small class="text-muted">{{ detail.tahun_terbit }}</small>
                </div>
                <div class="col-lg-4 mb-3">
                  <div>Kelompok Pembaca</div>
                  <small class="text-muted">
                    {{ detail.kelompok_pembaca }}
                  </small>
                </div>
                <div class="col-lg-4 mb-3">
                  <div>Kepengarangan</div>
                  <small class="text-muted">{{ detail.kepengarangan }}</small>
                </div>
                <div class="col-lg-4 mb-3">
                  <div>Sinopsis</div>
                  <small class="text-muted" v-if="!showMore">
                    {{ detail.sinopsis.slice(0, 200) }}...
                    <span
                      v-show="detail.sinopsis.length >= 200"
                      @click="showMore = true"
                      class="text-primary"
                      style="cursor: pointer"
                      >Lihat lebih</span
                    >
                  </small>
                  <small v-else>
                    {{ detail.sinopsis }}
                    <span
                      @click="showMore = false"
                      class="text-primary"
                      style="cursor: pointer"
                    >
                      Lihat sedikit
                    </span>
                  </small>
                </div>
                <div class="col-lg-4 mb-3">
                  <div>Distributor</div>
                  <small class="text-muted">{{ detail.distributor }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal -->
        <div
          class="modal fade w-100"
          id="readModal"
          tabindex="-1"
          aria-labelledby="readModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="readModalLabel">
                  {{ detail.judul }}
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <object
                  type="application/pdf"
                  :data="lampiran"
                  width="100%"
                  height="800"
                  aria-label="readModal"
                >
                  <p>Silahkan klik tombol unduh untuk membaca</p>
                  <a className="btn btn-light" :href="detail.prelim">
                    <i className="fas fa-fw fa-download" />
                    Unduh
                  </a>
                </object>
              </div>
            </div>
          </div>
        </div>
        <!-- End Modal -->
      </div>
      <div v-else>
        <div class="text-center my-5">
          <img
            width="60"
            src="@/assets/not-found.png"
            class="img-fluid"
            alt=""
          />
          <h4 class="text-center mt-2">Buku tidak ditemukan.</h4>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "Book Detail",
  data() {
    return {
      loading: false,
      showMore: false,
      detail: {},
    };
  },
  computed: {
    lampiran() {
      return `https://static.buku.kemdikbud.go.id/uploader/files/${this.detail.lampiran}`;
    },
    prelim() {
      return `https://static.buku.kemdikbud.go.id/uploader/files/${this.detail.prelim}`;
    },
    checkLinkDrive() {
      return this.detail.cover_type == "drive" ? true : false;
    },
    imageURL() {
      return `https://static.buku.kemdikbud.go.id/uploader/files/${this.detail.cover}` ?? "https://image.web.id/images/Dummy.png";
    },
  },
  methods: {
    getDetailBook() {
      this.loading = true;
      const id = this.$route.params.id;
      axios
        .get(`https://api.buku.kemdikbud.go.id/api/isbn/publicDetail/${id}`, {
          headers: {
            Authorization:
              "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiNTU5NiIsInVzZXJuYW1lIjoiY3ZwcmF0YW1hbWFpbGluYXRvcmNvbSIsImVtYWlsIjoiY3ZwcmF0YW1hQG1haWxpbmF0b3IuY29tIiwiZnVsbG5hbWUiOiJDViBQcmF0YW1hIFNhcmkiLCJyb2xlX25hbWUiOiJQZW5lcmJpdCIsInJvbGVfaWQiOiIxNSIsImF2YXRhciI6Imh0dHBzOlwvXC93d3cuZ3JhdmF0YXIuY29tXC9hdmF0YXJcL2E0MTlhMzQwOGZiMzBmYmY3NzcyNjI0Mjc0MmNlYjA5P3NpemU9MTAwJmQ9aHR0cHMlM0ElMkYlMkZzdGF0aWMuY2RuLWNkcGwuY29tJTJGc291cmNlJTJGMiUyRmNvZGVwb2xpdGFuLWRlZmF1bHQtYXZhdGFyLnBuZyJ9.YS06vKtZrl1P2bRRxlx3PZ_xVZAiIjCEIK8KgDTBAR0",
          },
        })
        .then((res) => {
          this.detail = res.data;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
  },
  created() {
    this.getDetailBook();
  },
};
</script>
